import { brandsToExclude, categoriesToExclude } from '../queries/toExclude';

import { FetchAllBrands } from '../components/hooks/fetchAllBrands';
import { FetchAllCategories } from '../components/hooks/fetchAllCategories';
import useAuth from '../components/useAuth';
import { useMemo } from 'react';

// *FILTERING PRODUCTS
export const useFilteredProducts = (allProducts: any[]) => {
	const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();
	const brands = FetchAllBrands();
	const categories = FetchAllCategories();

	const filteredProducts = useMemo(() => {
		let allFilteredProducts = allProducts;

		if (isDefaultCustomer || isUnlicensedUser || !loggedIn) {
			allFilteredProducts = allProducts.filter((item: any) => {
				return item.productTags?.nodes.every(
					(tag: any) =>
						tag.name !== 'Unlicensed' && tag.name !== 'Pharmaceuticals'
				);
			});
		}
		return allFilteredProducts;
	}, [isDefaultCustomer, isUnlicensedUser, allProducts, loggedIn]);

	return filteredProducts;
};

// * FILTERING BRANDS
export const useFilteredBrands = (brands: any[]) => {
	const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();
	const filteredBrands = useMemo(() => {
		if (isDefaultCustomer || isUnlicensedUser || !loggedIn) {
			return brands.filter((item: any) => {
				return !brandsToExclude.includes(item.name);
			});
		}

		return brands.filter((item: any) => {
			return (
				item.name !== 'Unlicensed' &&
				item.name !== 'Medical Devices' &&
				item.name !== 'Pharmaceuticals'
			);
		});
	}, [isDefaultCustomer, isUnlicensedUser, brands, brandsToExclude]);

	return filteredBrands;
};

// * FILTERING CATEGORIES
export const useFilteredCategories = (categories: any[]) => {
	const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();

	const filteredCategories = useMemo(() => {
		if (isDefaultCustomer || isUnlicensedUser || !loggedIn) {
			return categories.filter((item: any) => {
				return !categoriesToExclude.includes(item.name);
			});
		}

		return categories;
	}, [isDefaultCustomer, isUnlicensedUser, categories, categoriesToExclude]);

	return filteredCategories;
};
