import { graphql, useStaticQuery } from 'gatsby';

// PRODUCTS
export const fetchAllVariableProducts = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpVariableProduct {
				totalCount
				nodes {
					name
					id
					slug
					databaseId
					image {
						sourceUrl
						altText
					}
					productTags {
						nodes {
							name
							id
							slug
						}
					}
					productCategories {
						cat: nodes {
							name
							id
							slug
						}
					}

					... on WpVariableProduct {
						id
						name
						price
						regularPrice
						onSale
						salePrice
					}
				}
			}
		}
	`);

	const variableProducts = data.allWpVariableProduct.nodes;

	// Combine and return both sets of products
	return variableProducts;
};
