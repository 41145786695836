export type ShopSortItem = {
	value: string;
	label: string;
};

export const PRODUCTS_PER_PAGE = 16;
export const BLOGS_PER_PAGE = 12;

export const SHOP_SORT_ITEMS: ShopSortItem[] = [
	{
		value: 'a-z',
		label: 'Name: A-Z',
	},
	{
		value: 'z-a',
		label: 'Name: Z-A',
	},
	{
		value: 'priceASC',
		label: 'Price-Low to High',
	},
	{
		value: 'priceDESC',
		label: 'Price-High to Low',
	},
];
